.App {
    text-align: center;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

$neon: #00fff8;

/* Mixins */
@mixin gradientBg($direction, $startColor, $endColor) {
    background: $startColor;
    background: -moz-linear-gradient(
        $direction,
        $startColor 0%,
        $endColor 80%,
        $endColor 100%
    );
    background: -webkit-linear-gradient(
        $direction,
        $startColor 0%,
        $endColor 80%,
        $endColor 100%
    );
    background: linear-gradient(
        $direction,
        $startColor 0%,
        $endColor 80%,
        $endColor 100%
    );
}

.no-select {
    user-select: none;
}

/* bg shortcodes */
.bg-gradient1 span,
.bg-gradient1:before {
    @include gradientBg(180deg, #fa7e29, #f6682f);
}
.bg-gradient2 span,
.bg-gradient2:before {
    @include gradientBg(180deg, #39c2c9, #3fc8c9);
}
.pop-onhover.bg-gradient3 span,
.pop-onhover.bg-gradient3:before {
    @include gradientBg(180deg, #b9aef0, #ada1eb);
}

.bg-gradient4 span {
    @include gradientBg(180deg, #f6682f, #f6682f);
}
// .bg-gradient4:before {
// 		@include gradientBg(180deg, #d2d6df, #666a73);
// }

/* General */
.wrapper {
    margin: 4% auto;
    text-align: center;
}

h3 {
    color: #666a73;
    font-weight: 300;
    letter-spacing: 0.06em;
}

a {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

.token {
    display: inline-block;
    user-select: none;

    .icon {
        padding-left: 0.25em;
    }
}

.token:active {
    transform: scale(0.9);
}

.icon:active {
    transform: scale(0.9);
}

/* fancy Button */
.fancy-button {
    display: inline-block;
    margin: 20px;
    font-family: "Heebo", Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    line-height: 24px;
    color: #ffffff;
    position: relative;

    &.bg-gradient1 {
        text-shadow: 0px 0px 1px #bf4c28;
    }

    &.bg-gradient2 {
        text-shadow: 0px 0px 1px #227270;
    }

    &.bg-gradient3 {
        text-shadow: 0 0 1px #546082;
    }

    &:before {
        content: "";
        display: inline-block;
        height: 40px;
        position: absolute;
        bottom: -1px;
        left: 10px;
        right: 10px;
        z-index: -1;
        border-radius: 2em;
        filter: blur(14px) brightness(0.9);
        transform-style: preserve-3d;
        transition: all 0.3s ease-out;
    }
    i {
        margin-top: -2px;
        font-size: 1.265em;
        vertical-align: middle;
    }
    span {
        display: inline-block;
        padding: 16px 20px;
        border-radius: 50em;
        position: relative;
        z-index: 2;
        will-change: transform, filter;
        transform-style: preserve-3d;
        transition: all 0.3s ease-out;
    }
    &:focus {
        color: #ffffff;
    }
    &:hover {
        color: #ffffff;

        span {
            filter: brightness(0.9) contrast(1.2);
            transform: scale(0.96);
        }
        &:before {
            bottom: 3px;
            filter: blur(6px) brightness(0.8);
        }
    }
    &:active span {
        filter: brightness(0.75) contrast(1.7);
    }
    &.pop-onhover {
        span {
            border-radius: 4px;
        }
        &:before {
            opacity: 0;
            bottom: 10px;
        }
        &:hover {
            &:before {
                bottom: -7px;
                opacity: 1;
                filter: blur(16px);
            }
            span {
                // transform: scale(1.03);
                transform: scale(1);
            }
            &:active {
                span {
                    filter: brightness(1) contrast(1);
                    transform: scale(1);
                    transition: all 0.2s ease-out;
                }
                &:before {
                    bottom: 0;
                    filter: blur(5px) brightness(0.85);
                    transition: all 0.2s ease-out;
                }
            }
        }
    }
}

.pop-onhover.bg-gradient3 span:hover {
    @include gradientBg(120deg, #b9aef0, #ada1eb);
}

//solid to gradient

.bg-gradient4:before {
    bottom: 2px;
    opacity: 0.6;
    transition: all 0.3s ease-out;
}

.bg-gradient4 {
    transition: all 1s ease;
    span {
        outline: 0px solid transparent;
    }
    &:hover span {
        @include gradientBg(-25deg, #fc3d7c, #f76a34);
    }
    &:focus span,
    &:active span {
        @include gradientBg(25deg, #fc3d7c, #f76a34);
    }
    &:focus span {
        // outline: 2px solid $neon;
        box-shadow: 0 0 9px $neon;
    }
    &:active span {
        filter: brightness(0.85) contrast(1.3);
    }
}

.bg-gradient4 span {
    text-transform: capitalize;
}

// Input styles
input {
    border-style: none;
    background: transparent;
    outline: none;
}

button {
    padding: 0;
    background: none;
    border: none;
    outline: none;
}

.container {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    height: 100vh;
    max-width: 1600px;
    padding: 0 15px;
    margin: 0 auto;
}

@media screen and (max-width: 480px) {
    .container {
        // font-size: 10px;
        width: 90%;
    }

    .webflow-style-input {
        padding: 1rem 0.5rem 1rem;
    }

    .webflow-style-input input {
        font-size: 1.5rem;
    }
}

@media screen and (min-width: 480px) {
    .container {
        // font-size: 10px;
        width: 90%;
    }

    .webflow-style-input {
        padding: 1.6rem 2rem 1.5rem;
    }

    .webflow-style-input input {
        font-size: 2rem;
    }
}

// colors
$input-background: rgba(57, 63, 84, 0.8);
$input-text-inactive: #7881a1;
$input-text-active: #bfd2ff;

// gradient animation
@keyframes gradient {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.webflow-style-input {
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 400px;
    margin: 0 auto;
    border-radius: 2px;
    background: $input-background;
    &:after {
        content: "";
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 999;
        height: 2px;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        background-position: 0% 0%;
        background: linear-gradient(
            to right,
            #b294ff,
            #57e6e6,
            #feffb8,
            #57e6e6,
            #b294ff,
            #57e6e6
        );
        background-size: 500% auto;
        animation: gradient 3s linear infinite;
    }
}

.webflow-style-input input {
    flex-grow: 1;
    color: $input-text-active;
    vertical-align: middle;
    display: inline-block;
    width: 90%;
    &::-webkit-input-placeholder {
        color: $input-text-inactive;
    }
}

.webflow-style-input button path {
    fill: $input-text-inactive;
    color: $input-text-inactive;
    font-size: 2.4rem;
    line-height: 2.4rem;
    vertical-align: middle;
    transition: color 0.25s;

    &:hover {
        color: $input-text-active;
        fill: $input-text-active;
    }
}
